import React from "react";
import { 
    Box,
    Button,
    Center,
    ChakraProvider,
    FormControl,
    FormLabel,
    Grid,
    Input,
    Text,
    Textarea,
    useMediaQuery
} from "@chakra-ui/react";
import { FiSend } from "react-icons/fi";
import { menuItems } from "../../utils/menuItems";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

const CostumerService = () => {
    const [isLargerThan1024] = useMediaQuery('(min-width: 1023px)');
    const [isLargerThan1440] = useMediaQuery('(min-width: 1438px)');
    
    return (
        <ChakraProvider bg={'#F5F5F5'}>
            <Grid>
                <Box m={0} p={5} backgroundSize={"cover"} bgImage={require('../../assets/img/desktop-1920x253.jpg')}>
                    <Header items={menuItems} />
                </Box>

                <Box p={isLargerThan1440||isLargerThan1024?20:0} mt={10}>
                    <Box  ml={isLargerThan1440||isLargerThan1024?"20%":"10px"} mr={isLargerThan1440||isLargerThan1024?"20%":"10px"} shadow='md' borderWidth='1px'>
                        <Box p={isLargerThan1440||isLargerThan1024?10:5}>
                            <Text
                                textAlign={'center'}
                                fontSize={'35px'}
                                fontWeight={'650'}
                                color={'#1565C0'}
                            >Comunícate con nosotros</Text>

                            <FormControl>
                                <FormLabel htmlFor='name'>Nombre</FormLabel>
                                <Input id='name' type='text' placeholder="Escribe tu nombre" />
                            </FormControl>

                            <FormControl>
                                <FormLabel htmlFor='email'>Correo electrónico</FormLabel>
                                <Input id='email' type='email' placeholder="correo@electronico.com" />
                            </FormControl>

                            <FormControl>
                                <FormLabel htmlFor='issue'>Asunto</FormLabel>
                                <Input id='issue' type='text' placeholder="Escribe el asunto" />
                            </FormControl>

                            <FormControl>
                                <FormLabel htmlFor='message'>Mensaje</FormLabel>
                                <Textarea id='message' />
                            </FormControl>

                        </Box>
                        <Center pb={10}>
                            <Button leftIcon={<FiSend />} w={isLargerThan1440||isLargerThan1024?300:150} fontSize={20} color={"#fff"} bgColor={"#1565C0"} variant='solid'>
                                Enviar
                            </Button>
                        </Center>
                    </Box>
                    

                </Box>

                <Footer />
            </Grid>
        </ChakraProvider >
    );
};

export default CostumerService;