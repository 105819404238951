import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  useDisclosure,
  CloseButton,
  Link,
  VStack,
  DrawerFooter,
  Text,
  Image,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import Logo from '../assets/img/nd-play.svg'
import { filterItems } from '../functions/filterItems';

const HamburgerMenu = ({ items }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const itemsToShow = filterItems(items, ['privacy-polices']);

  return (
    <>
      <VStack onClick={onOpen} _hover={{ cursor: 'pointer' }}>
        <IconButton
          aria-label='Menu'
          icon={<HamburgerIcon color='white' boxSize='32px' />}
          variant='unstyled'
          size='lg'
          boxSize='32px'
        />
        <Text fontSize='12px' color='white' textAlign='center' margin='0'>
          Menú
        </Text>
      </VStack>
      <Drawer placement='left' onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent bg='linear-gradient(#1E50A0, #1565C0)' p='18px 30px'>
          <DrawerHeader>
            <Flex justifyContent='space-between' p='10px'>
              <Link as={RouterLink} to='/' w='92px' _focus={{ border: 'none' }}>
                <Image src={Logo} alt='ND App Logo' maxW='92px' />
              </Link>
              <CloseButton size='lg' onClick={onClose} color='white' />
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            <VStack alignItems='flex-start' spacing='28px'>
              {itemsToShow?.map(({ title, uri }) => (
                <Link
                  as={RouterLink}
                  to={`/${uri}`}
                  key={uri}
                  textColor='white'
                  _activeLink={{ color: '#FFF300' }}
                >
                  {title}
                </Link>
              ))}
            </VStack>
          </DrawerBody>
          <DrawerFooter textAlign='left'>
            <VStack w='100%' alignItems='flex-start' spacing='16px'>
              Contacto
            </VStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default HamburgerMenu;
