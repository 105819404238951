import React from "react";
import {
    Box,
    Center,
    ChakraProvider,
    Grid,
    HStack,
    Image,
    Text,
    useMediaQuery,
    VStack
} from "@chakra-ui/react";
import { menuItems } from "../../utils/menuItems";
import Footer from "../../components/Footer";
import Phone from "../../assets/img/8-nd-play.png"
import Header from "../../components/Header";
const NdPlay = () => {
    const [isLargerThan1024] = useMediaQuery('(min-width: 1023px)');
    const [isLargerThan1440] = useMediaQuery('(min-width: 1438px)');
    return (
        <ChakraProvider bg={'#F5F5F5'}>
            <Grid>
                <Box m={0} p={5} backgroundSize={"cover"}  bgImage={require('../../assets/img/desktop-1920x253.jpg')}>
                    <Header items={menuItems} />
                </Box>

                <Box mb={10} p={isLargerThan1440 || isLargerThan1024 ? 20 : "10px"}>
                    <Text
                        textAlign={'center'}
                        fontSize={isLargerThan1440 || isLargerThan1024 ?'45px':'30px'}
                        fontWeight={'630'}
                        color={'#1565C0'}
                    >
                        ¿Qué es Nuestro Diario Play?
                    </Text>
                    <Center>

                    {isLargerThan1440 || isLargerThan1024?
                            <HStack mt={10}>
                                <Box margin={10} textAlign={'center'} fontSize={28} color={'#707070'}>
                                    <Text>
                                        Nuestro Diario Play, es el app que ofrece a los lectores de<br />
                                        Nuestro Diario, el diario de mayor circulación de<br />
                                        Guatemala, la opción de poder ver <b>más contenido<br />
                                        audiovisual</b> de las noticias y notas del diario impreso.<br />
                                    </Text>
                                    <br />
                                    <Text>
                                        Es un app diseñado exclusivamente para los<br />
                                        guatemaltecos, que quieren estar enterados de las noticias<br />
                                        de Guatemala y el mundo, pero que a su vez quieren<br />
                                        informarse, educarse y entretenerse a través de videos,<br />
                                        fotografías y audios, y que quieren <b>obtener más contenido</b><br />
                                        de valor sobre el impreso.
                                    </Text>
                                </Box>
                                <Box margin={10}>
                                    <Image src={Phone}  alt="PHONE" />
                                </Box>
                            </HStack>
                        :
                        <VStack mt={5}>
                            <Box margin={2} textAlign={'center'} fontSize={17} color={'#707070'}>
                                <Text>
                                    Nuestro Diario Play, es el app que ofrece a 
                                    los lectores de Nuestro Diario, el diario de 
                                    mayor circulación de Guatemala, la opción 
                                    de poder ver <b>más contenido audiovisual</b> de 
                                    las noticias y notas del diario impreso. 
                                </Text>
                                <br />
                                <Text>
                                    Es un app diseñado exclusivamente para los 
                                    guatemaltecos, que quieren estar enterados 
                                    de las noticias de Guatemala y el mundo, 
                                    pero que a su vez quieren informarse, 
                                    educarse y entretenerse a través de videos, 
                                    fotografías y audios, y que quieren <b>obtener 
                                    más contenido</b> de valor sobre el impreso.
                                </Text>
                            </Box>
                            <Box margin={10}>
                                <Image src={Phone} m={"0 auto"} w={"80%"} alt="PHONE" />
                            </Box>
                        </VStack>

                    }
                        
                    </Center>
                </Box>

                <Footer />

            </Grid>
        </ChakraProvider>
    );
}

export default NdPlay;