import React from "react";
import { Box, Center, Grid, HStack, Image, Text } from "@chakra-ui/react";
import Phone from "../../../assets/img/8-nd-play.png"

const IntroductionMobile = () => {
    return (
        <Grid>
            <Box bg={'#F5F5F5'} mb={10}>

                <HStack mt={10} mb={3}>
                    <Box>
                        <Image src={Phone} margin={"0 auto"} ml={"15px"} alt="PHONE" w={"100%"} />
                    </Box>
                    <Box textAlign={'center'}  color={'#707070'}>
                        <Text
                            fontSize={17}
                            m={5}
                        >
                            Con la aplicación de Nuestro Diario Play tienes ecceso a<br />
                            <b>fotografías, videos y audios</b> de las noticias y notas<br />
                            del diario impreso del día.
                        </Text>
                        <br />
                    </Box>
                </HStack>
                <Text
                    fontSize={17}
                    textAlign={'center'}
                    color={'#707070'}
                    mb={10}
                >
                    Para ver contenido multimendia lo único que<br />
                    debes hacer es comprar el impreso del día <br />
                    y descargar la aplicación.
                </Text>
            </Box>
        </Grid>
    );
}

export default IntroductionMobile;