import React from "react";
import { Box, Center, Grid, Image, Text, VStack } from "@chakra-ui/react";
import Video from "../../../assets/img/video.svg";
import Fotos from "../../../assets/img/fotos.svg";
import Deportes from "../../../assets/img/deportes.svg";
import Entrevistas from "../../../assets/img/entrevistas.svg";
import Mas from "../../../assets/img/y-mas.svg";
const ContainerMobile = () => {
    return (
        <Grid bg={"#1565C0"}>
            <Box mt={20} mb={20}>
                <Text
                    textAlign={'center'}
                    fontSize={'30px'}
                    fontWeight={'630'}
                    color={'#FFF300'}
                >El contenido al que tendras acceso con Nuestro Diario Play es:</Text>
                <Center>
                    <VStack ml={15} mr={15} spacing={20} textAlign={"center"}>
                        <Box>
                            <Image src={Video} margin={"0 auto"} w={"50%"} alt="Contenido"/>
                            <Text
                                fontSize={25}
                                color={"#fff"}
                            >
                                Videos y noticias con más<br />
                                detalles de los sucesos

                            </Text>
                        </Box>
                        <Box>
                            <Image src={Fotos} margin={"0 auto"} w={"50%"} alt="Contenido"/>
                            <Text
                                fontSize={25}
                                color={"#fff"}
                            >
                                fotografías adicionales de<br />
                                las noticias
                            </Text>
                        </Box>
                        <Box>
                            <Image src={Deportes} margin={"0 auto"} w={"50%"} alt="Contenido"/>
                            <Text
                                fontSize={25}
                                color={"#fff"}
                            >
                                Videos con resúmenes de<br />
                                eventos deportivos
                            </Text>
                        </Box>
                    </VStack>
                </Center>
                <Center>
                    <VStack mt={30} spacing={20} textAlign={"center"}>
                        <Box>
                            <Image src={Entrevistas} margin={"0 auto"} w={"50%"} alt="Contenido"/>
                            <Text
                                fontSize={25}
                                color={"#fff"}
                            >
                                Entrevistas
                            </Text>
                        </Box>
                        <Box>
                            <Image src={Mas} margin={"0 auto"} w={"90%"} alt="Contenido"/>
                            <Text
                                fontSize={25}
                                color={"#fff"}
                            >
                                Y más
                            </Text>
                        </Box>
                    </VStack>
                </Center>
            </Box>
        </Grid>
    );
}

export default ContainerMobile;