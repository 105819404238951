import React, { Fragment } from "react";
import { NavLink as RouterLink } from 'react-router-dom';
import Logo from '../assets/img/nd-play.svg'
import { Box, Flex, HStack, Image, useMediaQuery, Link, Grid, Spacer } from "@chakra-ui/react";
import { filterItems } from "../functions/filterItems";

const NavBar = ({ items }) => {
    const [isLargerThan1440] = useMediaQuery('(min-width: 1438px)');
    const itemsToShow = filterItems(items, ['privacy-polices']);

    return (
        <Fragment>
            <Box marginTop={5} w='100%' bgColor='transparent' mt={55}>
                <Grid>
                    <Flex alignItems='center'>
                        <Box pl={'40'}>
                            <Link
                                as={RouterLink}
                                to={'/'}
                                maxW={isLargerThan1440 ? '200px' : '150px'}
                                mr='64px'
                                minW={isLargerThan1440 ? '200px' : '150px'}
                            >
                                <Image src={Logo} alt='ND App Logo' w={isLargerThan1440 ? '50%' : '30%'} />
                            </Link>
                        </Box>
                        <Spacer />
                        <Box marginRight={100} marginTop={-20}>
                            <HStack w='100%' spacing='24px'>
                                {itemsToShow?.map(({ title, uri }) => {
                                    return (
                                        <Link
                                            as={RouterLink}
                                            to={`/${uri}`}
                                            key={uri}
                                            textColor='white'
                                            fontSize={isLargerThan1440 ? '24px' : '20px'}
                                            _activeLink={{ color: '#FFF300' }}
                                        >
                                            {title}
                                        </Link>
                                    )
                                })}
                            </HStack>
                        </Box>
                    </Flex>
                </Grid>
            </Box>
        </Fragment>
    )
}

export default NavBar;