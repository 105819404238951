import React from "react";
import { Box, ChakraProvider, Grid, Text, useMediaQuery } from "@chakra-ui/react";
import { menuItems } from "../../utils/menuItems";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

const PrivacyPolices = () => {
    const [isLargerThan1024] = useMediaQuery('(min-width: 1023px)');
    const [isLargerThan1440] = useMediaQuery('(min-width: 1438px)');

    return(
        <ChakraProvider bg={'#F5F5F5'}>
            <Grid>
                <Box m={0} p={5} backgroundSize={"cover"}  bgImage={require('../../assets/img/desktop-1920x253.jpg')}>
                    <Header items={menuItems} />
                </Box>
                <Box ml={isLargerThan1024||isLargerThan1440?"15%":"5%"} mr={isLargerThan1024||isLargerThan1440?"15%":"5%"}>
                    <Text
                        mt={10}
                        textAlign={'center'}
                        fontSize={isLargerThan1024||isLargerThan1440?'45px':'30px'}
                        fontWeight={'650'}
                        color={'#1565C0'}
                    >Políticas de privacidad</Text>

                    <Box mt={isLargerThan1024||isLargerThan1440?10:5} mb={isLargerThan1024||isLargerThan1440?10:5} textAlign={"justify"}>

                        <Text fontSize={isLargerThan1024||isLargerThan1440?30:20}>
                            La presente Política de Privacidad establece los términos en que Diarios Modernos, S.A.
                            usa y protege la información personal que es proporcionada por sus usuarios al momento de 
                            utilizar la aplicación Nuestro Diario Play. Esta compañía está comprometida con la 
                            seguridad de los datos de sus usuarios.  
                            <br />
                            <br />
                            Cuando le pedimos llenar los campos de información personal con la cual usted puedalos 
                            usuarioes pueden ser identificados, lo hacemos asegurando que sólo se empleará de acuerdo 
                            con los términos de este documento.  
                            <br />
                            <br />
                            Sin embargo, esta Política de Privacidad puede cambiar con el tiempo o ser actualizada por 
                            lo que le recomendamos y enfatizamos a los usuarios revisarla continuamente para 
                            asegurarse que están de acuerdo con dichos cambios. 
                            <br />
                            <br />
                            Si desea contactarnos, lo puedenodrá hacer mediante correo electrónico, al: 
                            developer@nuestrodiario.com.gt.
                            <br />
                            <br />
                            <b>Información que es recogidarecopilada </b>
                            <br />
                            <br />
                            Nuestra aplicación podrá recoger información personal, por ejemplo:  
                            <br />
                            <br />
                            Nombre, información de contacto, como su dirección de correo electrónica, número 
                            telefónico e información demográfica. Así mismo cuando sea necesario podrá ser requerida 
                            información específica para procesar algún pedido o realizar una entrega o facturación. 
                            <br />
                            <br />
                            Nosotros no recolectamos ni almacenamos de manera intencional información personal de 
                            menores excepto como lo permite la ley aplicable. 
                            <br />
                            <br />
                            Si es residente de Estados UnidosGuatemala, menor de 18 años y un usuario registrado de 
                            la aplicación Nuestro Diario Play, puede solicitarnosn solicitar que retiremos el contenido o 
                            la información que nos se haya proporcionado al escribir a 
                            developer@nuestrodiario.com.gt. 
                            <br />
                            <br />
                            Por favor, tenerga en consideración que lassu solicitudes no aseguran una eliminación 
                            completa o integral del contenido o información, ya que, por ejemplo, parte de su contenido 
                            pudo haber sido publicado de nuevo por otro usuario. 
                            <br />
                            <br />
                            <b>Información que es compartida </b>
                            <br />
                            <br />
                            Nuestra aplicación podrá almacenar información personal que haya sido compartida por 
                            usted los usuarios, por ejemplo: fotos, videos, enlaces, etc. Así mismo se podrá compartir 
                            opiniones y comentarios con varios usuarios dentro de la misma. 
                            <br />
                            <br />
                            <b>Uso de la información recogidarecopilada</b>
                            <br />
                            <br />
                            Nuestro Diario Play emplea la información con el fin de proporcionar el mejor servicio 
                            posible, particularmente para mantener un registro de usuarios, de pedidos en caso de que 
                            aplique y mejorar nuestros productos y servicios.  Es posible que sean enviados correos  
                            electrónicos o notificaciones push periódicamente a través de nuestro sitio con ofertas 
                            especiales, nuevos productos y otra información publicitaria que consideremos relevante 
                            para usted el usuario o que pueda brindarle algún beneficio, estas notificaciones serán 
                            enviadas a la dirección que usted sea proporcionadae o la ubicación brindada por la 
                            geolocalización y podrán ser cancelados en cualquier momento. Así mismo pueden llegar 
                            mensajes de texto al  su número de teléfono proporcionado con ofertas o información que 
                            consideremos relevante para el uso de la aplicación. 
                            <br />
                            <br />
                            Diarios Modernos, S.A. está altamente comprometido para cumplir con el compromiso de 
                            mantener lasu información de sus usuarios segura. Usamos los sistemas más avanzados y 
                            los actualizamos constantemente para asegurarnos que no exista ningún acceso no 
                            autorizado. Usaremos esfuerzos razonables para no revelar ninguna información que nos 
                            haya proporcionado a compañías u organizaciones no afiliadas sin su el consentimiento de 
                            los usuarios, salvo lo expresado en esta política y en casos que: a) Se nos requiera revelarlo 
                            por una ley; b) consideremos que es necesaria su revelación para responder una consulta 
                            realizada por usted los usuarios o proveerles algún servicio solicitado, o c) Para proteger los 
                            derechos, propiedad o seguridad personal de cualquier persona, incluido otro cliente de 
                            Diarios Modernos, S.A. O el público en general.  
                            <br />
                            <br />
                            Si bien tomaremos todas las precauciones razonables para proteger lasu información de los 
                            usuarios, es posible que, para poder proveer los servicios, sea necesario que ocasionalmente 
                            compartamos su información con un proveedor.  
                            <br />
                            <br />
                            <b>Menores de Edad</b>
                            <br />
                            <br />
                            Los menores de edad deberán contar con el consentimiento previo de sus padres o tutores 
                            para enviar información personal como nombre, correo electrónico, número telefónico y 
                            otros.
                            <br />
                            <br />
                            <b>Cookies</b>
                            <br />
                            <br /> 
                            Una cookie se refiere a un fichero que es enviado con la finalidad de solicitar permiso para 
                            almacenarse en lossu dispositivos del usuario; , al aceptar dicho fichero se crea y la cookie 
                            sirve entonces para tener información respecto al tráfico, y también facilita las futuras 
                            visitas. Otra función que tienen las cookies es que con ellas pueden reconocerte 
                            individualmente un usuario y por tanto brindarlte el mejor servicio personalizado dentro de 
                            su dispositivo. 
                            <br />
                            <br /> 
                            Nuestra app emplea las cookies para poder identificar las páginas que son visitadas y su 
                            frecuencia. Esta información es empleada únicamente para análisis estadístico y después 
                            utilizarla para mostrarle al usuario contenido que sea de mayor utilidad y agrado para 
                            ustedél (ella).  
                            <br />
                            <br /> 
                            Usted Se pueden eliminar las cookies en cualquier momento desde su la app. Sin embargo, 
                            las cookies ayudan a proporcionar un mejor servicio, estás no dan acceso a información de 
                            su el ordenador ni del usuario usted, a menos de que usted así lo quiera y la proporcione 
                            directamente.  
                            <br />
                            <br /> 
                            Usted El usuario puede aceptar o negar el uso de cookies, sin embargo la mayoría aceptan 
                            cookies automáticamente pues sirve para tener un mejor servicio. También usted 
                            puedepueden cambiar la configuración de su dispositivo para declinar las cookies. Si se 
                            declinan es posible que no puedan utilizar algunos de nuestros servicios. 
                            <br />
                            <br /> 
                            <b>Enlaces a Terceros</b>
                            <br />
                            <br /> 
                            Esta aplicación pudiera contener enlaces a otros sitios que pudieran ser de sul interés del 
                            usuario. Una vez que usted el usuario de clic en estos enlaces y abandone nuestra página, ya 
                            no tenemos control sobre al sitio al que es redirigido y por lo tanto no somos responsables 
                            de los términos o privacidad ni de la protección de sus datos en esos otros sitios terceros. 
                            Dichos sitios están sujetos a sus propias políticas de privacidad por lo cual es recomendable 
                            que los usuarios consulten para confirmar que usted están de acuerdo con éstas. 
                            <br />
                            <br />
                            <b>Control de su infromación personal</b>
                            <br />
                            <br />
                            En cualquier momento usted los usuarios pueden restringir la recopilación o el uso de la 
                            información personal que es proporcionada a nuestra aplicación.  Cada vez que se les 
                            solicite rellenar un formulario, como el de alta de usuario, pueden marcar o desmarcar la 
                            opción de recibir información por correo electrónico.  En caso de que haya marcado la 
                            opción de recibir nuestro boletín o publicidad usted pueden cancelarla en cualquier 
                            momento. 
                            <br />
                            <br />
                            Esta compañía utilizara lasu información personal del usuario para enviar boletines, ponerse 
                            en contacto vía SMS, Whatsapp, corro electrónico o notificaciones push, cambios en 
                            nuestra app de términos, condiciones y políticas, informar sobre actualizaciones o 
                            novedades, para permitir contactar y ser contactado por usuarios, participar en foros, chats, 
                            blogs, y otros donde se pueda compartir materiales e información, para nuestros propósitos 
                            de análisis y gestión del negocio, investigación de mercados,  auditorias, desarrollo de 
                            productos, mejoras, identificar tendencias de uso, eficacia de campañas publicitarias, medir 
                            satisfacción de cliente y experiencia a través de nuestra aplicación, utilizamos información 
                            recopilada a través de su los dispositivos del usuario para fines estadísticos para el buen 
                            funcionamiento, localización física para ofrecer servicios de localización y contenido 
                            personalizado combinado con publicidad. 
                            <br />
                            <br />
                            <b>Diarios Modernos, S.A. Se reserva el derecho de cambiar los términos de la presente 
                            Política de Privacidad en cualquier momento.</b>
                        </Text>

                    </Box>
                </Box>
                <Footer />
            </Grid>
        </ChakraProvider>
    );
};

export default PrivacyPolices