import React, { Fragment } from 'react';
import {
  BrowserRouter,
  Switch,
  Route
} from "react-router-dom";
import costumerService from './pages/costumerService/costumerService';
import Landing from './pages/landing/Landing';
import NdPlay from './pages/ndPlay/ndPlay';
import PrivacyPolices from './pages/privacyPolices/privacyPolices';

function App() {
  return (
    <Fragment>
      <BrowserRouter>
        <Switch>
          <Route exact path={'/'} component={Landing} />
          <Route exact path={'/about-app'} component={NdPlay} />
          <Route exact path={'/costumer-service'} component={costumerService} />
          <Route exact path={'/privacy-polices'} component={PrivacyPolices} />
          <Route path={'*'} component={Landing} />
        </Switch>
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
