import React from "react";
import HeaderBackground from "../../../assets/img/mobile-480x712.jpg";
import PhoneOne from "../../../assets/img/1-nd-play.png";
import {
    Box,
    Text,
    VStack,
    Grid,
    HStack,
    Image,
    Container,
} from '@chakra-ui/react';
import Header from "../../../components/Header";
const HomeMobile = ({ items }) => {

    return (
        <Grid>
            <Box
                minH={520}
                backgroundPosition={'center'}
                backgroundSize={'cover'}
                backgroundRepeat={'no-repeat'}
                backgroundImage={HeaderBackground}
            >

                <Header items={items} />
                <Grid>
                    <Container mt={'10'}>
                        <Text
                            textAlign={'center'}
                            fontSize={'30px'}
                            fontWeight={'500'}
                            color={'#FFF300'}
                        >
                            ¡Descárgala ahora,<br />es gratis!
                        </Text>
                        <br />
                        <Text
                            textAlign={'center'}
                            fontSize={'18px'}
                            mt={'-5'}
                            color={'#fff'}
                        >
                            Nuestro Diario Play, la aplicación que te<br />
                            permite ver más fotografías, video y <br />
                            escuchar audios de las noticias del <br />
                            diario impreso.
                        </Text>
                        <br />
                    </Container>
                </Grid>
            </Box>
            <Box mt={-40}>
                <HStack>
                    <VStack>
                        <Box w={"100%"} mt={40} ml={5}>
                            <Image src={require("../../../assets/img/googleplay.png")} mb={5} objectFit={'contain'} alt="GOOGLE PLAY" />
                            <Image src={require("../../../assets/img/appstore.png")} objectFit={'contain'} alt="APP STORE" />
                        </Box>
                    </VStack>
                    <Image src={PhoneOne} m={0} alt='Phone One' w={"60%"} />
                </HStack>
            </Box>
        </Grid>
    )
}

export default HomeMobile;