import React from "react";
import { Box, HStack, Image, Text, VStack, Center, Grid } from "@chakra-ui/react";

const Numeration = ({ text }) => {
    return (
        <Box bg={"#FFF200"} borderRadius="50px" w={'70px'} h={'70px'}>
            <Text
                mt={"17%"}
                textAlign={'center'}
                fontWeight={'bold'}
                fontSize={30}
                color={'#195A93'}> {text} </Text>
        </Box>
    )
};

const TutorialDesktop = () => {
    return (
        <Grid bg={'#fff'}>
            <Box textAlign={'center'} mt={20}>
                <Text color={'#1565C0'} fontSize={45} fontWeight={700} >¿Cómo descargar Nuestro Diario Play?</Text>
            </Box>
            <Box mb={20}>
                <VStack mt={10}>
                    <Box w={"100%"} textAlign={'left'}>
                        <Box ml={"20%"} mr={"20%"}>
                            <HStack>
                                <Numeration text={'1'} />
                                <Box>
                                    <Text
                                        fontSize={28}
                                        textAlign={'left'}
                                        color={'#707070'}
                                    >
                                        Identifica la <span style={{ color: "#757575", fontWeight: "600" }}>tienda para descargar aplicaciones</span> en tu dispositivo<br />
                                        (App Store o Google Play)
                                    </Text>
                                </Box>
                            </HStack>
                            <HStack>
                                <Center>
                                    <Box w={"15%"} m={'5'}>
                                        <Image src={require("../../../assets/img/GooglePlay-512x512.png")} objectFit={'contain'} />
                                    </Box>
                                    <Box w={"15%"}>
                                        <Image src={require("../../../assets/img/App-Store-Logo.png")} objectFit={'contain'} />
                                    </Box>
                                </Center>
                            </HStack>
                        </Box>
                    </Box>

                    <Box w={"100%"} textAlign={'left'}>
                        <Box ml={"20%"} mr={"20%"}>
                            <HStack>
                                <Numeration text={'2'} />
                                <Box>
                                    <Text
                                        fontSize={28}
                                        textAlign={'left'}
                                        color={'#707070'}
                                    >
                                        Busca <span style={{ color: "#757575", fontWeight: "600" }}>Nuestro Diario Play</span> de dentro de la tienda y descarga la<br />
                                        aplicación
                                    </Text>
                                </Box>
                            </HStack>
                            <Box m={'5'} textAlign={'center'}>
                                <Text
                                    fontSize={24}
                                    color={'#707070'}
                                >También puedes hacer click en los botones de abajo para<br /> descargalo</Text>
                                <Center>
                                    <Image src={require("../../../assets/img/googleplay.png")} w={"30%"} alt="GOOGLE PLAY" />
                                    <Image src={require("../../../assets/img/appstore.png")} w={"30%"} alt="GOOGLE PLAY" />
                                </Center>
                            </Box>
                        </Box>
                    </Box>

                    <Box w={"100%"} textAlign={'left'}>
                        <Box  ml={"20%"} mr={"20%"}>
                            <HStack>
                                <Numeration text={'3'} />
                                <Box>
                                    <Text
                                        fontSize={28}
                                        textAlign={'left'}
                                        color={'#707070'}
                                    >
                                        Compre el ejemplar de Nuestro Diario del día
                                    </Text>
                                </Box>
                            </HStack>
                            <Box>
                                <Center>
                                    <Image src={require("../../../assets/img/diario.png")} w={"40%"} alt="DIARIO" />
                                </Center>
                            </Box>
                        </Box>
                    </Box>

                    <Box w={"100%"} textAlign={'left'}>
                        <Box ml={"20%"} mr={"20%"}>
                            <HStack>
                                <Numeration text={'4'} />
                                <Box>
                                    <Text
                                        fontSize={28}
                                        textAlign={'left'}
                                        color={'#707070'}
                                    >
                                        Ingrese el <span style={{ color: "#757575", fontWeight: "600" }}>código de la numerada</span> que aparece en la portada del<br />
                                        impreso
                                    </Text>
                                </Box>
                            </HStack>
                            <Box>
                                <Center>
                                    <Image src={require("../../../assets/img/2-nd-play.png")} w={"35%"}/>
                                </Center>
                            </Box>
                        </Box>
                    </Box>

                    <Box w={"100%"} textAlign={'left'}>
                        <Box ml={"20%"} mr={"20%"}>
                            <HStack>
                                <Numeration text={'5'} />
                                <Box>
                                    <Text
                                        fontSize={28}
                                        textAlign={'left'}
                                        color={'#707070'}
                                    >
                                        Busca el contenido del diario que tienen los <span style={{ color: "#757575", fontWeight: "600" }}>códigos QR</span>
                                    </Text>
                                </Box>
                            </HStack>
                            <Box>
                                <Center>
                                    <Image src={require("../../../assets/img/3-nd-play.png")} w={"35%"} />
                                </Center>
                            </Box>
                        </Box>
                    </Box>

                    <Box w={"100%"} textAlign={'left'}>
                        <Box ml={"20%"} mr={"20%"}>
                            <HStack>
                                <Numeration text={'6'} />
                                <Box>
                                    <Text
                                        fontSize={28}
                                        textAlign={'left'}
                                        color={'#707070'}
                                    >
                                        <span style={{ color: "#757575", fontWeight: "600" }}>Escanea los códigos</span> y disfruta de todo el contenido multimedia<br />
                                    </Text>
                                </Box>
                            </HStack>
                            <Box>
                                <Center>
                                    <Image src={require("../../../assets/img/4-nd-play.png")} w={"35%"}/>
                                </Center>
                            </Box>
                        </Box>
                    </Box>
                </VStack>
            </Box>
        </Grid>
    );
}

export default TutorialDesktop;