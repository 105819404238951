import React from "react";
import { NavLink as RouterLink } from 'react-router-dom';
import { Box, Center, HStack, Image, Link, Text, useMediaQuery, VStack } from "@chakra-ui/react";
import LOGO from "../assets/img/logo-nuestro-diario.svg"
import Facebook from "../assets/img/facebook-blanco-01.svg"
import Instagram from "../assets/img/instagram-blanco-01.svg"
import { filterItems } from "../functions/filterItems";
import { menuItems } from "../utils/menuItems";
const Footer = () => {
    let yaer = new Date();
    let itemsToShow = filterItems(menuItems, []);
    const [isLargerThan1024] = useMediaQuery('(min-width: 1023px)');
    const [isLargerThan1440] = useMediaQuery('(min-width: 1438px)');

    return (
        <Box bg={"#1565C0"} m={0} p={0}>
            <Box p={isLargerThan1024 || isLargerThan1440 ? 10 : 5} textAlign={isLargerThan1024 || isLargerThan1440 ? "center" : "left"}>

                {
                    isLargerThan1024 || isLargerThan1440 ?
                        <Center>
                            <HStack spacing={60} p={20}>
                                {itemsToShow?.map(({ title, uri }) => {
                                    return (
                                        <Link
                                            as={RouterLink}
                                            to={`/${uri}`}
                                            key={uri}
                                            textColor='#fff'
                                            fontSize={isLargerThan1440 ? '24px' : '20px'}
                                            _activeLink={{ color: '#FFF300' }}
                                        >
                                            {title}
                                        </Link>
                                    )
                                })}
                            </HStack>
                        </Center>
                        :
                        <VStack spacing={5} alignItems={'baseline'}>
                            {itemsToShow?.map(({ title, uri }) => {
                                return (
                                    <Link
                                        as={RouterLink}
                                        to={`/${uri}`}
                                        key={uri}
                                        textColor='#fff'
                                        textAlign={'left'}
                                        fontSize={isLargerThan1024 || isLargerThan1440 ? '24px' : '20px'}
                                        _activeLink={{ color: '#FFF300' }}
                                    >
                                        {title}
                                    </Link>
                                )
                            })}
                        </VStack>
                }
            </Box>

            <Box textAlign={"center"} p={20}>
                <Center>
                    <Image src={LOGO} w={isLargerThan1024 || isLargerThan1440 ? "20%" : "80%"} alt="NUESTRO DIARIO" />
                </Center>
                <Text color={"#fff"} fontSize={isLargerThan1024 || isLargerThan1440 ? 20 : 15}>&copy; {yaer.getFullYear()} Nuestro Diario.</Text>
            </Box>
        </Box >
    )
}

export default Footer