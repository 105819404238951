export const menuItems = [
  {
    title: '¿Qué es ND Play?',
    uri: 'about-app',
  },
  {
    title: 'Servicio al cliente',
    uri: 'costumer-service',
  },
  {
    title: 'Políticas de Privacidad',
    uri: 'privacy-polices',
  },
];
