import React from "react";
import {
    Box,
    Text,
    Grid,
    Center,
    HStack,
    Image,
    VStack,
} from '@chakra-ui/react';
import Biblioteca from "../../../assets/img/5-nd-play.png";
import Favoritos from "../../../assets/img/6-nd-play.png";
import Promociones from "../../../assets/img/7-nd-play.png";

const LastSectionDesktop = () => {
    return (
        <Box bg={"#fff"}>
            <Box ml={35} mt={20} mb={20}>
                <Text
                    textAlign={'center'}
                    fontSize={'40px'}
                    fontWeight={'650'}
                    color={'#1565C0'}
                >
                    Al descargar ND Play, no sólo podrás ver el contenido del impreso<br />
                    sino también podrás:
                </Text>

                <Center>
                    <HStack spacing={40} mt={20}>
                        <Box textAlign={"center"}>
                            <VStack>
                                <Image src={Biblioteca} margin={"0 auto"} w={"80%"} alt="Contenido"/>
                                <Text fontSize={27}>
                                    Ver contenido que has<br />
                                    descargado anteriormente
                                    <br />
                                    <br />
                                </Text>
                            </VStack>
                        </Box>
                        <Box textAlign={"center"}>
                            <VStack>
                                <Image src={Favoritos} margin={"0 auto"} w={"80%"} alt="Contenido"/>
                                <Text fontSize={27}>
                                    Marcar noticias como<br />
                                    favoritas
                                    <br />
                                    <br />
                                </Text>
                            </VStack>
                        </Box>
                        <Box textAlign={"center"}>
                            <VStack>
                                <Image src={Favoritos} mb={10} margin={"0 auto"} w={"80%"} alt="Contenido"/>

                                <Text fontSize={27}>
                                    Participar en promociones<br />
                                    exclusivas para usuarios de<br />
                                    la aplicación
                                </Text>

                            </VStack>
                        </Box>

                    </HStack>
                </Center>
            </Box>
        </Box>
    )
}

export default LastSectionDesktop;