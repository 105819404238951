import React from "react";
import {
    ChakraProvider,
    Box,
    VStack,
    theme,
    useMediaQuery,
} from '@chakra-ui/react';
import { menuItems } from "../../utils/menuItems";
import HomeDesktop from "../sections/Desktop/HomeDesktop";
import TutorialDesktop from "../sections/Desktop/TutorialDesktop";
import HomeMobile from "../sections/Mobile/HomeMobile";
import ContainerDesktop from "../sections/Desktop/ContenidoDesktop";
import LastSectionDesktop from "../sections/Desktop/LastSectionDesktop";
import Footer from "../../components/Footer";
import IntroductionDesktop from "../sections/Desktop/IntroductionDesktop";
import IntroductionMobile from "../sections/Mobile/IntroductionMobile";
import TutorialMobile from "../sections/Mobile/TutorialMobile";
import ContainerMobile from "../sections/Mobile/ContenidoMobile";
import LastSectionMobile from "../sections/Mobile/LastSectionMobile";

const Landing = () => {
    const [isLargerThan460] = useMediaQuery('(min-width: 460px)');
    const [isLargerThan280] = useMediaQuery('(min-width: 280px)');
    const [isLargerThan1024] = useMediaQuery('(min-width: 1023px)');
    const [isLargerThan1440] = useMediaQuery('(min-width: 1438px)');

    const Desktop = () => (
        <>
            <HomeDesktop items={menuItems} />
            <IntroductionDesktop />
            <TutorialDesktop />
            <ContainerDesktop />
            <LastSectionDesktop style={{ padding: "5"}} />
            <Footer />
        </>
    )

    const Mobile = () => (
        <>
            <HomeMobile items={menuItems} />
            <IntroductionMobile />
            <TutorialMobile />
            <ContainerMobile />
            <LastSectionMobile />
            <Footer />
        </>
    )

    return (
        <ChakraProvider theme={theme}>
            <Box fontSize="xl">
                <VStack align={'stretch'} spacing={0}>
                    {
                        isLargerThan1440?( Desktop() )
                        :
                        isLargerThan1024?( Desktop() )
                        :
                        isLargerThan460?( Mobile()  )
                        :
                        isLargerThan280&&( Mobile() )
                    }
                    {/* <Grid>
                        <HStack>
                            <Center pl={60} pt={65}>
                                <Box>
                                    <Image src={PhoneTwo} alt="Phone Two" />
                                </Box>
                                <Box p={30}>
                                    <Center>
                                        <Text fontSize={30}>
                                            Con las aplicaciones de Nuestro Diaio Play tienes acceso a<br />
                                            <b>fotografías, videos y audios</b> de las noticias y notas del día<br />
                                            del diario impreso.<br />
                                        
                                            Para ver el contenido multimedia lo unico que debes hacer<br />
                                            es comprar el impreso del día y descargar la aplicación.
                                        </Text>
                                    </Center>
                                </Box>
                            </Center>
                        </HStack>
                    </Grid> */}

                </VStack>
            </Box>
        </ChakraProvider>
    );
}

export default Landing;