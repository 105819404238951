import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Box,
    Center,
    Heading,
    HStack,
    Text,
    VStack,
    Image,
    Link,
    useMediaQuery,
} from '@chakra-ui/react';
import Logo from '../assets/img/nd-play.svg'
import NavBar from './Nav';
import HamburgerMenu from './HamburgerMenu';

const Header = ({ items }) => {
    const [isLargerThan460] = useMediaQuery('(min-width: 460px)');
    const [isLargerThan1024] = useMediaQuery('(min-width: 1023px)');
    const [isLargerThan1440] = useMediaQuery('(min-width: 1438px)');
    return (
        <Box
            maxW='100%'
            bgRepeat='no-repeat'
            bgPosition='center'
            bgAttachment={isLargerThan460 ? 'fixed' : 'scroll'}
        >
            <Box minW='100%'>
                <VStack minW='100%'>
                    <Box w='100%'>
                        {isLargerThan1024 ? (
                            <NavBar items={items} />
                        ) : (
                            <HStack mt={5}>
                                <HamburgerMenu items={items} />
                                <Center w='100%' justifySelf='center' alignSelf='center'>
                                    <Link as={RouterLink} to={'/'}>
                                        <Image src={Logo} alt='ND Play App Logo' minW='92px' />
                                    </Link>
                                </Center>
                            </HStack>
                        )}
                    </Box>
                </VStack>
            </Box>
        </Box>
    );
};

export default Header;