import React from "react";
import PhoneOne from "../../../assets/img/1-nd-play.png";
import {
    Box,
    Text,
    Grid,
    Center,
    HStack,
    Image,
} from '@chakra-ui/react';
import Header from "../../../components/Header";
const HomeDesktop = ({ items }) => {

    return (
        <Grid maxH={975} backgroundImage={require('../../../assets/img/desktop-1920x1022.jpg')} >
            <Header items={items} />
            <Grid>
                <Center>
                    <HStack>
                        <Box w={"60%"} pl={36}>
                            <Text
                                textAlign={'center'}
                                fontSize={'27px'}
                                mt={'-40'}
                                color={'#fff'}
                            >
                                Nuestro Diario Play, la aplicación que te permite ver
                                más fotografías, video y escuchar audios de las noticias
                                del diario impreso.
                            </Text>
                            <br />
                            <Text
                                textAlign={'center'}
                                fontSize={'45px'}
                                fontWeight={'630'}
                                color={'#FFF300'}
                            >
                                ¡Descárgala ahora, es gratis!
                            </Text>
                            <br />
                            <HStack>
                                <Center>
                                    <Image src={require("../../../assets/img/googleplay.png")} w={"30%"} alt="GOOGLE PLAY" />
                                    <Image src={require("../../../assets/img/appstore.png")} w={"30%"} alt="APP STORE" />
                                </Center>
                            </HStack>
                        </Box>
                        <Box w={"30%"}>
                            <Image src={PhoneOne} alt='Phone One' />
                        </Box>
                    </HStack>
                </Center>
            </Grid>
        </Grid>
    )
}

export default HomeDesktop;