import React from "react";
import { Box, Center, Grid, HStack, Image, Text } from "@chakra-ui/react";
import Phone from "../../../assets/img/8-nd-play.png"

const IntroductionDesktop = () => {
    return (
        <Grid>
            <Box bg={'#F5F5F5'}>
                <Center>

                    <HStack mt={10} mb={10}>
                        <Box margin={10}>
                            <Image src={Phone} alt="PHONE" />
                        </Box>
                        <Box margin={10} textAlign={'center'} fontSize={28} color={'#707070'}>
                            <Text>
                                Con la aplicación de Nuestro Diario Play tienes ecceso a<br />
                                <b>fotografías, videos y audios</b> de las noticias y notas<br />
                                del diario impreso del día.
                            </Text>
                            <br />
                            <Text>
                                Para ver contenido multimendia lo único que debes hacer<br />
                                es comprar el impreso del día y descargar la aplicación.<br />
                            </Text>
                        </Box>
                    </HStack>
                </Center>
            </Box>
        </Grid>
    )
}

export default IntroductionDesktop;